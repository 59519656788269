export const blogList = [
  {
    id: 73,
    title: 'Da Vinci | Atulya Sharma',
    category: 'Software',
    company: 'Da Vinci',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "People interested in making a career in quant industry / startup culture / C++ development.Position is ideal for someone who has a keen interest in Machine Learning research and would like to explore the field more",
    createdAt: 'August 8, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1046/format:webp/1*51TpstNy_Dic3r6XHV7hVg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-da-vinci-atulya-sharma-cf659adb550b',
  },

  {
    id: 74,
    title: 'Navi Technologies | Divyanshu Gupta',
    category: 'Product',
    company: 'Navi Technologies',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I feel that it’s the best role for someone who is preparing for product roles as their primary option in CDC Placements.",
    createdAt: 'August 8, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*DxfpnZf9x3-iPKmHo76VaA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-navi-technologies-divyanshu-gupta-72c885b5eb18',
  },

  {
    id: 75,
    title: 'EXL | Loya Vivek',
    category: 'Consult',
    company: 'EXL',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I feel that it’s the best role for someone who is preparing for product roles as their primary option in CDC Placements.",
    createdAt: 'August 16, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*5NQCgfFq7YMx-G-hBGIvHw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-exl-loya-vivek-5df956a1374f',
  },
  {
    id: 76,
    title: 'EXL | Loya Vivek',
    category: 'Consult',
    company: 'EXL',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I feel that it’s the best role for someone who is preparing for product roles as their primary option in CDC Placements.",
    createdAt: 'August 16, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*5NQCgfFq7YMx-G-hBGIvHw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-exl-loya-vivek-5df956a1374f',
  },
  {
    id: 77,
    title: 'TVS | Ankit Anuragi',
    category: 'Data',
    company: 'TVS',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I feel that it’s the best role for someone who is preparing for product roles as their primary option in CDC Placements.",
    createdAt: 'August 18, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1152/format:webp/1*hFr3WbYAO5oirE3ryp3V_w.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-tvs-motor-ankit-anuragi-068a33791f65',
  },
  {
    id: 78,
    title: 'Capital One | Aman Kedia',
    category: 'Finance',
    company: 'Capital One',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who loves challenges, and has a great analytical and problem-solving mindset would be a great fit.",
    createdAt: 'August 20, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*681P60tmqT-gWO17Jo2oCA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-capital-one-aman-kedia-112d887438525',
  },
  {
    id: 79,
    title: 'Qualcomm | Surya Sai Ram',
    category: 'Core',
    company: 'Qualcomm',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who is interested in digital hardware design verification, or chip design and wants to explore more of the field can apply for this job.",
    createdAt: 'August 23, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*AwbOpMV1QSx5KF0iIJU7CQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-qualcomm-surya-sai-ram-be66d772b196',
  },
  {
    id: 80,
    title: 'Navi | Vibhushit Agarwal',
    category: 'Software',
    company: 'Navi',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Those who are interested in software engineering and are ready to learn challenging technologies like Spring Boot should ideally apply for this job.",
    createdAt: 'August 26, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:826/format:webp/1*zMSQKGa-CeHHdq25gBuMPg.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-navi-vibhushit-agarwal-0060ea684d3f',
  },
  {
    id: 81,
    title: 'Tata AIG | Tanish Goel',
    category: 'Product',
    company: 'Tata AIG',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Having previous product management experience would boost your chances.",
    createdAt: 'August 27, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*i8vSKIipMCCZ5EQtTYotQg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-tata-aig-tanish-goel-ecac5b563b18',
  },
   
  {
    id: 82,
    title: 'HSBC | Shalmali Sriram',
    category: 'Finance',
    company: 'HSBC',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone with a knack for problem-solving is a good fit for the job, just make sure that you hone your communication and technical skills.",
    createdAt: 'September 1, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*e6-fugpB1031MphYVVfy9w.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-hsbc-shalmali-sriram-f1c5367e0be6',
  },
  {
    id: 83,
    title: 'Navi Technologies | Anubhav Prasad ',
    category: 'Data',
    company: 'Navi Technologies',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Prepare well and do some good projects and internships to build a strong resume.",
    createdAt: 'September 3, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:800/format:webp/1*4gVerAYdtmFvnDGcXTDmCA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-navi-technologies-anubhav-prasad-9c69955500d6',
  },
  {
    id: 84,
    title: 'Schlumberger | Ritanuka Ghosh ',
    category: 'Core',
    company: 'Schlumberger',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " All geoscientist people who are willing to accept challenges and learn new things must consider applying for this role. ",
    createdAt: 'September 7, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1112/format:webp/1*wfdgmU6eCioGR-vQX5uzhw.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-schlumberger-ritanuka-ghosh-ce7839d1c4db',
  },
  {
    id: 85,
    title: 'Squarepoint Capital | Vishal Ravipati ',
    category: 'Sofware',
    company: 'Squarepoint Capital',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " While most SDE companies are achievable by people of any department, this will be significantly more difficult for Squarepoint due to their focus on CS topics. ",
    createdAt: 'September 9, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*TAYHPJAjdM4fJZ8vH0sOFw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-squarepoint-capital-vishal-ravipati-2f49295fbb90',
  },

  {
    id: 86,
    title: 'Citi | Sagar Kumar Karn ',
    category: 'Finance',
    company: 'Citi',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " If you have an interest in Data Science and like have interest in using machine learning in formulating and developing models for risk modelling, then this is the ideal job.  ",
    createdAt: 'September 11, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*97VszsovN2OCZxBZif9hZg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-citi-sagar-kumar-karn-ca351cd8cf45',
  },
  {
    id: 87,
    title: 'Nvidia | Kartik Pontula ',
    category: 'Software',
    company: 'Nvidia',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " Any aspiring software engineer who is more of a systems guy than a competitive programmer may find their place here.  ",
    createdAt: 'September 14, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*rgwq6mQgg0awUdb0YByWMQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-nvidia-karthik-pontula-90f65e90bbd3',
  },
  
  {
    id: 88,
    title: 'Intel | C R Vivroshan ',
    category: 'Core',
    company: 'Intel',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " Physics background students in semiconductor domains.  ",
    createdAt: 'September 15, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Fy094mjf6bwuHsdP_gZL5A.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-intel-c-r-vivroshan-a844d7a9b7a9',
  },
  {
    id: 89,
    title: 'Nvidia | Dhruv Rathi ',
    category: 'Core',
    company: 'Nvidia',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anybody interested in GPUs and cutting-edge technologies should apply for this job.  ",
    createdAt: 'September 27, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*ZTJzGdWjx_kSRqYnz4dh5A.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-placement-at-nvidia-dhruv-rathi-1d604bf06072',
  },

  {
    id: 47,
    title: 'Rakuten Group | Abhishant Kumar',
    category: 'Software',
    company: 'Rakuten Group',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "An ideal candidate for this application is someone who is interested in the field of software development and engineering.",
    createdAt: 'August 16, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*fMkmrAF7DWaQ-GwCupKG5g.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-rakuten-group-abhishant-kumar-d1eda9fb35f2',
  },

  {
    id: 48,
    title: 'Tiger Analytics | Rajdeep Ghosh',
    category: 'Data',
    company: 'Tiger Analytics',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Any student who is interested in the data profile should apply for this role.",
    createdAt: 'August 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:600/format:webp/1*Gs1FlFxGchO4K-l-ffw-bA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-tiger-analytics-rajdeep-ghosh-e76f5cb799d3',
  },

  {
    id: 49,
    title: 'Bain | Debraj Chatterjee',
    category: 'Consult',
    company: 'Bain',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I feel people who like analyzing businesses and the problems surrounding them should consider management consulting as a possible option.",
    createdAt: 'August 21, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*ORRQtTyeGYsT-bFAHgoWTg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-bain-company-debraj-chatterjee-661328725e08',
  },

  {
    id: 50,
    title: 'Schlumberger | Nishita Tripathy',
    category: 'Core',
    company: 'Schlumberger',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone having a strong communication and technology lover should definitely apply for this role.",
    createdAt: 'August 24, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*Hold--XwFuANnq7__eZ4sQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-schlumberger-nishita-tripathy-65ac40601348',
  },

  {
    id: 51,
    title: 'TSMC | Soumyajit Chakraborty',
    category: 'Software',
    company: 'TSMC',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "If you are interested in pursuing a career in hardware, TSMC is undoubtedly the dream company to work for.",
    createdAt: 'August 28, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*aZ9NAaaZ_AGpbOIYzdemkQ.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-tsmc-soumyajit-chakraborty-552c3abd8c6a',
  },

  {
    id: 52,
    title: 'AlphaGrep | Pavan Sai Chandra',
    category: 'Quant',
    company: 'AlphaGrep',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Folks who are interested in Computer Science and systems would be ideal for this job.",
    createdAt: 'September 2, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*pYagP4Uh2X5Y19y1zMz2uw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-alphagrep-pavan-sai-chandra-dbdaa6d6bd64',
  },

  {
    id: 53,
    title: 'American Express | Swagat Susmoy Sahoo',
    category: 'Data',
    company: 'American Express',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "If you are someone with a genuine interest in analyst roles in data or business then you may consider American Express as your first company.",
    createdAt: 'September 5, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*PhVeXR1QHdyuGKglev5WMg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-american-express-swagat-susmoy-sahoo-2def7f4b22c7',
  },

  {
    id: 54,
    title: 'Mastercard | Raushan Sharma',
    category: 'Software',
    company: 'Mastercard',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "If Data Structures and Algorithms is something that interests you, and you like problem-solving, you are good to go and apply for this job profile.",
    createdAt: 'September 9, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1078/format:webp/1*JNB5lMiZHibwdI5z8q0DsQ.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-mastercard-raushan-sharma-d0dc98b3c439',
  },

  {
    id: 55,
    title: 'Vedanta | Avilipsa Tripathy',
    category: 'Core',
    company: 'Vedanta',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "According to me if you want to pursue a career in geology then Oil company would be great.",
    createdAt: 'September 10, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*d6tkXg80sRJKqZmZE8QnGA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-cairn-oil-gas-vedanta-ltd-avilipsa-tripathy-3c80a2609436',
  },

  {
    id: 56,
    title: 'American Express | Gauri Karkhanis',
    category: 'Finance',
    company: 'American Express',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "If you are interested in analytics and management, I wholeheartedly encourage you to apply. A finance background can also be advantageous in this field.",
    createdAt: 'September 13, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*SIGXE5A8n1AeVEav2ISVpA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-american-express-gauri-karkhanis-b4b3544ff454',
  },

  {
    id: 57,
    title: 'Kotak Mahindra Bank | Job Steven James N.',
    category: 'Product',
    company: 'Kotak Mahindra Bank',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I realized this after I started working, really — the PM role is an absolute ace of a role for you to fast-track your corporate ladder journey.",
    createdAt: 'September 17, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*JFR0SoGoVncKhSiP2eNH0w.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-kotak-mahindra-bank-limited-job-steven-james-n-65581b56b6bb',
  },

  {
    id: 58,
    title: 'Siemens | Aashish Raj',
    category: 'Software',
    company: 'Siemens',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "People targeting software roles and preparing for software profiles are eligible and well-suited for this job.",
    createdAt: 'September 27, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*_IoGQSpwJGpbB_gieZxXig.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-siemens-eda-aashish-raj-56c7047b948',
  },

  {
    id: 59,
    title: 'Collins Aerospace | Singh Sumeet Subodh',
    category: 'Core',
    company: 'Siemens',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Those interested in a core mechanical job with a decent package or want to grow their career in the aerospace sector can apply for this job.",
    createdAt: 'September 29, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*I__QvVuY5__fvjwvo8kFEg.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-collins-aerospace-singh-sumeet-subodh-635aa2ada7fe',
  },
  {
    id: 60,
    title: ' TCG Digital | Manish Chouhan',
    category: 'Consult',
    company: ' TCG Digital',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "13 students were selected for TCG Digital from KGP; they are from different fields, viz. Front-End, Back-End, Data Science.",
    createdAt: 'October 1, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*mnCBqaJnBEeZEBjsHUHQqw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-tcg-digital-manish-chouhan-cee902cba858',
  },
  {
    id: 61,
    title: 'Accenture (Japan) | Sarnavo Sarkar',
    category: 'Software',
    company: 'Accenture',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "For those with a genuine interest in immersing themselves in Japanese culture or embarking on an early exploration of life abroad, I wholeheartedly recommend this company as an ideal choice.",
    createdAt: 'October 9, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*Tep3fpQPFI6lx82hgPjnGw.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-accenture-japan-sarnavo-sarkar-11219c0fc4f0',
  },
  {
    id: 62,
    title: 'Vedanta | Rohan Roy',
    category: 'Core',
    company: 'Vedanta',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Most geology, geophysics, petroleum engineering, and mining engineering students are allowed to appear for this job profile.",
    createdAt: 'October 12, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*LuV_ckKfXn-2N_d5LunFGw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-cairn-oil-gas-vedanta-ltd-rohan-roy-f6fa0cb57537',
  },

  {
    id: 63,
    title: 'Sprinklr | Rajnandni Sharma',
    category: 'Product',
    company: 'Sprinklr',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Someone who possesses a unique blend of tech-driven mindset, management acumen, and exceptional problem-solving abilities should apply for this job",
    createdAt: 'October 15, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*y3bE8sBIaS1YkA940cayww.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-sprinklr-rajnandni-sharma-2ffb342fbbfb',
  },

  {
    id: 64,
    title: 'Jaguar Land Rover | Praveen Yadav',
    category: 'Core',
    company: 'Jaguar Land Rover',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Jaguar Land Rover (JLR) typically offers opportunities in three distinct roles: Software, Embedded, and Mechatronics teams. Aspiring candidates should earnestly consider these roles.",
    createdAt: 'October 18, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*isH7wRXPrbynatmPlmW8kw.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-jaguar-land-rover-praveen-yadav-b03eb6daa1e8',
  },

  {
    id: 65,
    title: 'Walmart | Guruvansh Singh Bhatia',
    category: 'Software',
    company: 'Walmart',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone having an interest in problem-solving and software development must apply for it.",
    createdAt: 'October 23, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*a4WjSQh_gH_H8HOzqRNQZw.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-walmart-guruvansh-singh-bhatia-5f2927319229',
  },

  {
    id: 66,
    title: 'ANZ Banking Group | Vaibhav Prasad',
    category: 'Quant',
    company: 'ANZ Banking Group',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Those who have explored the quant field, are intrigued by the mystical world of the application of mathematical models in finance, and have a knack for statistics should definitely prepare for these roles.",
    createdAt: 'October 27, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*54b4L_IPC6I1XLI6PpWCwQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-anz-banking-group-vaibhav-prasad-aca9ce726e89',
  },
  
  {
    id: 67,
    title: 'General Mills | Yogeshwar Gupta',
    category: 'Product',
    company: 'General Mills',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "If you’re aspiring to build a career in supply chain management, this role is tailor-made for you",
    createdAt: 'November 3, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:800/format:webp/1*HUrO-Byurc1QUja3VjVXQw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-general-mills-yogeshwar-gupta-9cf5562e5c9a',
  },
 
  {
    id: 68,
    title: 'Apple | Mohit Gupta|',
    category: 'Software',
    company: 'Apple',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students aspiring to become software developers should consider applying for the SDE role at Apple, especially with the company’s plans to expand its software base in India.",
    createdAt: 'November 6, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*EfE_Wo_LkQXlq2-6HOKJfQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-apple-mohit-gupta-3bc07690bdaa',
  },
  {
    id: 69,
    title: 'Standard Chartered Bank | Akash Mudhol',
    category: 'Finance',
    company: 'Standard Chartered Bank',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who is interested in the finance or data science domain should definitely apply.",
    createdAt: 'November 27, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*iC1h-2SuMCOMqGrw2-2erw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-standard-chartered-bank-akash-mudhol-0e2fe3808ba0',
  },
  {
    id: 70,
    title: 'Sprinklr | Rajdeep Das',
    category: 'Product',
    company: 'Sprinklr',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Sprinklr is known for growth. The role is suitable for those who would like to put more effort into the early stage of their career.",
    createdAt: 'November 29, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*47phfMIczp4XTW8NhXER9Q.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-sprinklr-rajdeep-das-d546d3cf8c63',
  },
  {
    id: 71,
    title: 'Sprinklr | Parth Mane',
    category: 'Product',
    company: 'Sprinklr',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Sprinklr expects its employees to put in effort at work, and offers benefits to employees to keep them motivated ",
    createdAt: 'November 29, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1118/format:webp/1*MxsJz21J72LS_5nLn04Ldg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-sprinklr-parth-mane-ee0db1df4d73',
  },
  {
    id: 72,
    title: 'Vedanta | Arabinda A. Dhal',
    category: 'Core',
    company: 'Vedanta ',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Ideal candidates for the Geophysicist job in Cairn Oil & Gas, Vedanta Limited are those who have a strong passion for Geophysics, relevant internship experience",
    createdAt: 'November 30, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*zOGmB6cC3YOWEO1sjE0ruQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-placement-at-cairn-oil-and-gas-vedanta-ltd-arabinda-a-dhal-74435f9a7dd4',
  },
  {
    id: 1,
    title: 'Morgan Stanley | Compilation',
    category: 'Quant',
    company: 'Morgan Stanley',
    subCategory: ['adrenaline', 'stay-fit', 'lifestyle'],
    description: "The Associate role at Morgan Stanley involves a good mix of finance, data, and coding.",
    createdAt: 'November 20, 2022',
    cover: 'https://miro.medium.com/max/1100/1*NqIH-nzNZOQBGrKWqAM8LA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-morgan-stanley-compilation-49c325742a6e',
  },
  {
    id: 2,
    title: 'OLA | Compilation',
    category: 'Core',
    company: 'Ola',
    subCategory: ['e-commerce store', 'clothing', 'shopping store'],
    description:
      "People who are interested in working for core Electrical companies can apply for this job.",
    createdAt: 'November 13, 2022',
    cover: 'https://miro.medium.com/max/1100/1*9wKxQYLK_lQ2JHzJ2qg5jQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-ola-compilation-3ede15cdfe0e',
  },
  {
    id: 3,
    title: 'Eaton | Compilation',
    category: 'Software',
    company: 'Eaton',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'November 07, 2022',
    cover: 'https://miro.medium.com/max/1100/1*MfV2DxJrZjMuOYCjOh-TsA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-eaton-compilation-3a01711f64f3',
  },
  {
    id: 4,
    title: 'Texas Instruments | Yashwanth Pavilla',
    category: 'Core',
    company: 'Texas Instruments',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'November 03, 2022',
    cover: 'https://miro.medium.com/max/1100/1*Rb4iuedO1RdOmlMkHre1cg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-texas-instruments-yashwanth-pavilla-1affcd69d378',
  },
  {
    id: 5,
    title: 'Bajaj Auto | G V Sai Harshit',
    category: 'Core',
    company: 'Bajaj',
    subCategory: ['frontend', 'ui/ux', 'design'],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    createdAt: 'November 01, 2022',
    cover: 'https://miro.medium.com/max/1100/1*od371qTZ-Rjt_j1dKB6FEA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-bajaj-auto-g-v-sai-harshit-5edc0ba07419',
  },
  {
    id: 6,
    title: 'ICICI Bank | Chitranshu Ranjan',
    category: 'Finance',
    company: 'ICICI Bank',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'October 30, 2022',
    cover: '/assets/images/chitranshu.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-icici-bank-chitranshu-ranjan-ad6f70acd830',
  },
  {
    id: 7,
    title: 'LAM Research | Vembakam Theja',
    category: 'Core',
    company: 'LAM Research',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'October 28, 2022',
    cover: 'https://miro.medium.com/max/828/1*aF-BU7Sc8I4Gsx4RNBeV8Q.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-lam-research-vembakam-theja-ecb9e87c70a8',
  },
  {
    id: 8,
    title: 'American Express | Adithya K S',
    category: 'Software',
    company: 'American Express',
    subCategory: ['bbq', 'food', 'lifestyle'],
    description:
      "The test for the management trainee profile is heavy in mathematics.",
    createdAt: 'October 25, 2022',
    cover: 'https://miro.medium.com/max/1100/1*h4YK_osnmVIwYQ9kTGn2Lg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-american-express-adithya-k-s-cf523320e0f2',
  },
  {
    id: 9,
    title: 'Tiger Analytics | Compilation',
    category: 'Data',
    company: 'Bajaj',
    subCategory: ['beaches', 'sea', 'holidays'],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    createdAt: 'October 23, 2022',
    cover:
      'https://miro.medium.com/max/1100/1*1gR6mwr_6yCFSE6b9k_u0A.webp',
      medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-tiger-analytics-compilation-3cce900f1d4a',
  },
  {
    id: 10,
    title: 'APT Portfolio | Sudhamsh Kukkadapu',
    category: 'Data',
    company: 'APT Portfolio',
    subCategory: ['vacation', 'holidays', 'sight seeing'],
    description:
      "EATON is a very good company to start your career with due to its working culture.",
    createdAt: 'October 22, 2022',
    cover: 'https://miro.medium.com/max/1100/0*IF304qq8z_l1vc3n',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-apt-portfolio-sudhamsh-kukkadapu-2d270e0894c7',
  },
 
  {
    id: 11,
    title: 'Honeywell | Harshit Jain',
    category: 'Software',
    company: 'Honeywell',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    createdAt: 'October 19, 2022',
    cover: '/assets/images/harshit.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-honeywell-harshit-jain-edbfb691d86a',
  },
  {
    id: 12,
    title: 'Morgan Stanley | Tanmay Shah',
    category: 'Finance',
    company: 'Morgan Stanley',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "It is a client-facing job and a collaborative profile so interpersonal skills are also very important.",
    createdAt: 'October 12, 2022',
    cover: 'https://miro.medium.com/max/786/1*YefRyNoF2MRajp3QIrCcPQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-morgan-stanley-tanmay-shah-ee794e317a43',
  },
  {
    id: 13,
    title: 'Jaguar Land Rover India Limited | Narapureddy Abhilaash Reddy',
    category: 'Core',
    company: 'Jaguar Land Rover',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students who want to pursue their career in the core electronics sector should apply for this job.",
    createdAt: 'October 10, 2022',
    cover: '/assets/images/abhilash.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-jaguar-land-rover-india-limited-narapureddy-abhilaash-reddy-e8dfec9a9b5d',
  },
  {
    id: 14,
    title: 'Groww | Abhijeet Mahato',
    category: 'Product',
    company: 'Groww',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students interested or inclined to work in the field of strategy or planning must try this profile.",
    createdAt: 'October 9, 2022',
    cover: 'https://miro.medium.com/max/1100/1*7ieqp6yhwrveM8rqlIDwzA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-groww-abhijeet-mahato-6d449dbe4cac',
  },
  {
    id: 15,
    title: 'Rakuten Mobile Inc. | Saikat Mandol',
    category: 'Data',
    company: 'Rakuten Mobile',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Even for the data science profile, there would be an initial coding round to shortlist students.",
    createdAt: 'October 8, 2022',
    cover: 'https://miro.medium.com/max/1100/1*1G4G1qiMmSEwIAOLpDfF-Q.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-rakuten-mobile-inc-saikat-mandol-eef4db00fd1f',
  },
  {
    id: 16,
    title: 'Graviton Research Capital LLP | Yash Butala',
    category: 'Software',
    company: 'Graviton',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone interested in trading or computer systems must apply for the job.",
    createdAt: 'October 7, 2022',
    cover: 'https://miro.medium.com/max/1100/1*srk5gqX3H2p68hfyzff4sw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-graviton-research-capital-llp-yash-butala-fd6bf9fdda9e',
  },
  {
    id: 17,
    title: 'DC Advisory | Ishan Das',
    category: 'Finance',
    company: 'DC Advisory',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Since the nature of the job is client-facing, they evaluate your communication skills and depth of knowledge.",
    createdAt: 'October 6, 2022',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-dc-advisory-ishan-das-1697cc851705',
  },
  {
    id: 18,
    title: 'Da Vinci Derivatives | Kousshik Raj',
    category: 'Software',
    company: 'Da Vinci Derivatives',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "I think people who are very interested in programming and Systems, in particular, will enjoy the work.",
    createdAt: 'October 6, 2022',
    cover: 'https://miro.medium.com/max/1100/1*5v8zPWuTqtPCwDrrJmebrw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-da-vinci-derivatives-kousshik-raj-30b50e3067e0',
  },
  {
    id: 19,
    title: 'Wells Fargo | Bharat Chaudhary',
    category: 'Software',
    company: 'Wells Fargo',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "There were two roles to apply one was Analytics and one was Technical Associate.",
    createdAt: 'October 4, 2022',
    cover: 'https://miro.medium.com/max/1100/1*V5dnkSXdgPnmvhFmxN-GqQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-wells-fargo-bharat-chaudhary-116465f623db',
  },
  {
    id: 20,
    title: 'Accenture | Pratyusha Mondal',
    category: 'Consultancy',
    company: 'Accenture',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Coming to this job, consulting is all about dealing with clients, and working in a team",
    createdAt: 'October 3, 2022',
    cover: '/assets/images/pratyusha.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-accenture-pratyusha-mondal-8bd3a1fcc618',
  },
  {
    id: 21,
    title: 'Mercedes-Benz | Renu Tanaji Rathod',
    category: 'Core',
    company: 'Mercedes-Benz',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "All the non-circuital branch students who are interested in research and simulations can apply for this role", 
    createdAt: 'September 30, 2022',
    cover: 'https://miro.medium.com/max/1100/1*jEqJiYOUADkAnPnfKMWw1g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-mercedes-benz-renu-tanaji-rathod-97fe863e6541',
  },
  {
    id: 22,
    title: 'Dream11 | Pujan Burad',
    category: 'Software',
    company: 'Dream11',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who’s good at coding and problem solving, has a keen interest in software development should apply.",
    createdAt: 'September 28, 2022',
    cover: 'https://miro.medium.com/max/720/0*erLgqD0Clajf_D5e',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-dream-11-pujan-burad-cd8a8665db2',
  },
  {
    id: 23,
    title: 'Qualcomm | Deepanshi Pandey',
    category: 'Software',
    company: 'Qualcomm',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students aspiring to work as software developers in the near future should apply for the role ",
    createdAt: 'September 19, 2022',
    cover: 'https://miro.medium.com/max/1100/1*RhZ29gn4EDRJfNPsU7no2w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-qualcomm-deepanshi-pandey-18a17f216a40',
  },
  {
    id: 24,
    title: 'Google | Vinay Kumar Agarwal',
    category: 'Software',
    company: 'Google',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "An ideal person to apply for this role would be one who has passion for technology ",
    createdAt: 'September 17, 2022',
    cover: '/assets/images/vinay.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-google-vinay-kumar-agarwal-72632fc60e18',
  },
  {
    id: 25,
    title: 'Microsoft | Abhay Bhadoriya',
    category: 'Software',
    company: 'Microsoft',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who likes to solve problems and code them.If you don’t have internship experience in the development field, you can still go for it.",
    createdAt: 'September 12, 2022',
    cover: 'https://miro.medium.com/max/786/1*SI7UNAAjWxCiriqRvwRHCA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-microsoft-abhay-bhadoriya-75547627513a',
  },
  {
    id: 26,
    title: 'Sprinklr | Ayush Khatarkar',
    category: 'Software',
    company: 'Sprinklr',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "All the technology enthusiasts and students who love coding should apply for this job.",
    createdAt: 'June 28, 2022',
    cover: 'https://miro.medium.com/max/640/1*x4o8i5kkeX4z4pdTVHyqTw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-sprinklr-ayush-khatarkar-f24b68c92466',
  },
  {
    id: 27,
    title: 'Google | Tias Mondal',
    category: 'Software',
    company: 'Google',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "The only requirement is strong problem-solving skills (Not only coding).",
    createdAt: 'June 22, 2022',
    cover: 'https://miro.medium.com/max/1100/1*S8aAZoK2lNsw96M0vLQMyg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-google-tias-mondal-2e9c06d58a02',
  },
  {
    id: 28,
    title: 'AlphaGrep | Satyam Sharma',
    category: 'Software',
    company: 'AlphaGrep',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " In my case, I am really passionate about Software Development. Since my second year I developed a deep interest in Systems.",
    createdAt: 'June 16, 2022',
    cover: 'https://miro.medium.com/max/1100/1*NQrDzeBcQ0e0hjHUnbOJqw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-alphagrep-satyam-sharma-c88b6eeea5b2',
  },
  {
    id: 29,
    title: 'Quadeye | Arnab Maiti',
    category: 'Quant',
    company: 'Quadeye',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "For Quant role, HFTs usually prefer 9+ CGPA students from circuit departments.",
    createdAt: 'June 13, 2022',
    cover: 'https://miro.medium.com/max/1100/1*gwE6VPd-Y1yqRWrp_9eNpQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-quadeye-arnab-maiti-ce1f4fbb57e9',
  },
  {
    id: 30,
    title: 'Zomato | Vishvesh Pandya',
    category: 'Product',
    company: 'Zomato',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "One of the best and worst things about this role is that it doesn’t require any specific skills or degrees.",
    createdAt: 'June 8, 2022',
    cover: 'https://miro.medium.com/max/1100/1*HgA5Fkof1XWoaKrBqqJJNg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-zomato-vishvesh-pandya-7c7eea0d2d9f',
  },
  {
    id: 31,
    title: 'Zomato | Arnab Moitra',
    category: 'Product',
    company: 'Zomato',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Do go through important CS topics like OOPS, OS, DBMS and try to learn SQL, this definitely would help along the way.",
    createdAt: 'May 19, 2022',
    cover: 'https://miro.medium.com/max/1100/1*5PpsEw3SyEhExDEskkVMYg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-zomato-arnab-moitra-e825f794b7a7',
  },
  {
    id: 32,
    title: 'AMEX | Rachana Verma',
    category: 'Finance',
    company: 'AMEX',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who is into Management and Strategy should apply.",
    createdAt: 'May 16, 2022',
    cover: 'https://miro.medium.com/max/1100/1*gD9rXcCzt5ehZPzzpniSTw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-amex-rachana-verma-e1c339cd58f2',
  },
  {
    id: 33,
    title: 'Grofers | Prakhar Gaur',
    category: 'Product',
    company: 'Grofers',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Ideally, people who like business problem solving, interacting with different people, and thinking out of the box should apply.",
    createdAt: 'May 14, 2022',
    cover: 'https://miro.medium.com/max/1100/1*mE1MnEBIX2jEqu74ImlI-w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-grofers-prakhar-gaur-762c60e17e92',
  },
  {
    id: 34,
    title: 'P&G | Keshav Bagri',
    category: 'FMCG',
    company: 'P&G',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "If you think you’re good with the fundamentals of your branch and you know to manage things efficiently while being in charge.",
    createdAt: 'May 10, 2022',
    cover: '/assets/images/keshav.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-p-g-keshav-bagri-3edaa5842cda',
  },
  {
    id: 35,
    title: 'BNY Mellon | Visesh Kaushik',
    category: 'Software',
    company: 'BNY Mellon',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who has a passion for technology, interested in Fin-Tech and targets Software Developer Role can apply for this job.",
    createdAt: 'May 2, 2022',
    cover: 'https://miro.medium.com/max/1100/1*fhVRWDdPm_U-HPoYILyEPw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-bny-mellon-visesh-kaushik-25b01d5a1204',
  },
 {
    id: 36,
    title: ' JP Morgan Chase | Gowri Jayaprakash',
    category: 'Quant',
    company: 'JP Morgan Chase',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who has an interest in math and is good at coding would find this role really interesting and rewarding.",
    createdAt: 'April 28, 2022',
    cover: 'https://miro.medium.com/max/306/1*1Ld0vWccrKzvvDy5wrG43g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-jp-morgan-chase-gowri-jayaprakash-b5045d4f784',
  },
 {
    id: 37,
    title: '  Qualcomm | Jogadenu Kalyanraman',
    category: 'Core',
    company: 'Qualcomm',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Students who have good knowledge in Analog design, Analog and Digital Communication, and Signal Processing.",
    createdAt: 'April 24, 2022',
    cover: 'https://miro.medium.com/max/1100/1*NxLoaAZ84HCcVWt-1sPdrg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-placement-at-qualcomm-jogadenu-kalyanraman-4f93f9cbab19',
  },
{
    id: 38,
    title: ' JLR | Vatsal Kapadia',
    category: 'Core',
    company: 'JLR',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who has interest in product engineering, system engineering, even AI and coding can apply for the job.",
    createdAt: 'December 2, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-vatsal-kapadia-working-at-jlr-b530e4de4280',
  },
{
    id: 39,
    title: '  Western Digitals | Shiva Surya',
    category: 'Core',
    company: '  Western Digitals',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "People  interested in the Electronics profile should apply for this.",
    createdAt: 'December 2, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-application-specific-integrated-circuit-asic-at-western-digitals-shiva-surya-e73897d5fde3',
  },
{
    id: 40,
    title: ' Barclays Global Services | Shishir Shahi',
    category: 'Software',
    company: 'Barclays Global Services',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " if you are interested in technology and its business implementation, Barclays will be a good fit for you.",
    createdAt: 'December 1, 2021',
    cover: 'https://miro.medium.com/max/1100/1*KhkpQ8lGPY6WVc2yrgaA8g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-graduate-analyst-at-barclays-global-services-shishir-shahi-3ec4b92da7bb',
  },
{
    id: 41,
    title: 'Amazon | Meet Joshi',
    category: 'Software',
    company: 'Amazon',
    subCategory: ['skill', 'design', 'passion'],
    description:
      " those who have a keen interest in the Software Development domain must apply.",
    createdAt: 'December 1, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-software-development-engineer-at-amazon-meet-joshi-53e9e764809d',
  },
{
    id: 42,
    title: 'JP Morgan Chase | Raj Kumar Singh',
    category: 'Quant',
    company: 'JP Morgan Chase',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Anyone who wants to work in the Finance industry has an interest in ML and Finance, coding can apply for this job.",
    createdAt: 'December 1, 2021',
    cover: '/assets/images/raj.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-qr-analyst-at-jp-morgan-chase-raj-kumar-singh-a4e1af919443',
  },
{
    id: 43,
    title: ' Cisco | Vineeth',
    category: 'Software',
    company: 'Cisco',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "For a software profile, there are more application-oriented roles, OS, networking oriented roles. There are few ML-related roles also.",
    createdAt: 'December 1, 2021',
    cover: 'https://miro.medium.com/max/786/1*v1xzKfjNgR0Bb_ErvPHsUA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-software-developer-at-cisco-vineeth-a59d86a3fd9b',
  },
{
    id: 44,
    title: ' Dr Reddy’s Laboratories | Shakul',
    category: 'Core',
    company: "Dr Reddy’s Laboratories",
    subCategory: ['skill', 'design', 'passion'],
    description:
      "The ideal candidate would be one who is interested in pharmaceutical manufacturing. The company  value  those who display knowledge in production challenges.",
    createdAt: 'December 1, 2021',
    cover: 'https://miro.medium.com/max/1100/1*z8WZvM9ipAXG-kpFh2e58w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-shakul-from-dr-reddys-laboratories-8deb1d9bd7fd',
  },
{
    id: 45,
    title: 'Mercedes Benz | Anuraag Bhattacharya',
    category: 'Data',
    company: 'Mercedes Benz',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "People who are fairly proficient in Data Structures and Algorithms and interested to work in the field of ML/DL should apply.",
    createdAt: 'December 1, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-post-graduate-engineer-trainee-researcher-at-mercedes-benz-anuraag-bhattacharya-d82b7dd39b09',
  },
{
    id: 46,
    title: 'Google | Sohan Rudra',
    category: 'Software',
    company: 'Google',
    subCategory: ['skill', 'design', 'passion'],
    description:
      "Position is ideal for someone who has a keen interest in Machine Learning research and would like to explore the field more",
    createdAt: 'November 30, 2021',
    cover: 'https://miro.medium.com/max/1100/1*1g46yRxSr4zwhX88NbiXPQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-pre-doctoral-researcher-at-google-sohan-rudra-b4e9c87ad8ad',
  },

  
];
 

